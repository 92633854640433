import React from "react"
import { Link } from "react-router-dom";

const P3 = () => {
    return (
        <>

            <div class="breadcrumb">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-7">
                            <div class="part-txt">
                                <h1>Real Time Automation Controller</h1>
                                <ul>
                                    <li><Link to={"/"}>Home</Link></li>
                                    <li><i class="icofont-double-right"></i></li>
                                    <li class="current">Real Time Automation Controller</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="service-details">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-xl-3 col-lg-4 col-md-5">
                            <div class="sidebar">
                                <h2>Service Category</h2>

                                <div class="nav flex-column nav-pills three"  >
                                    <Link class="nav-link " to={"/industrial_panel_pc"}><span><i class="icofont-double-right"></i></span>Industrial Panel PC</Link>
                                    <Link class="nav-link " to={"/fanless_industrial_computer"}><span><i class="icofont-double-right"></i></span>Fanless Industrial Computer</Link>
                                    <Link class="nav-link active " to={"/real_time_automation"}><span><i class="icofont-double-right"></i></span>Real Time Automation</Link>
                                </div>
                                {/* <h2>Download Brochure</h2>
                                <div class="btns">
                                    <Link href="product3.php#" class="download-btn">Brochure.PDF<span><i class="icofont-file-pdf"></i></span></Link>
                                    {/* <!-- <Link href="service-details.html#" class="download-btn two">Brochure.DOC<span><i
                                        class="icofont-file-document"></i></span></Link> --> 
                                </div> */}
                            </div>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-7 next">
                            <div class="tab-content" id="v-pills-tabContent">
                                <div class="tab-pane fade show active" id="v-pills-one" role="tabpanel" aria-labelledby="v-pills-one-tab">
                                    <div class="main-content">
                                        <div class="row">
                                            <style>
                                                {/* @media only screen and (min-device-width: 481px) {
                                        .aaa {
                                            padding-left: 80px;
                                            padding-right: 80px;


                                        }
                                    }

                                    @media only screen and (max-device-width: 480px) {

                                        .aaa {

                                            width: 100% !important;
                                        }
                                    }

                                    .abc {
                                        color: black !important;
                                    } */}
                                            </style>

                                            <img src="assets/images/product/p_8_texas_automations.jpg" width="100%" alt="image" class="aaa" />
                                        </div>
                                        <div class="part-txt">
                                            <div class="quote" style={{ textAlign: "left" }}>
                                                <h2 class="abc">Information : </h2>
                                                <ul>
                                                    <li><span class="strong" style={{fontSize:"26px"}}>Intel® Core™ 6/7/8 gen i7/i5/i3/Pentium/Celeron Desktop High Performance CPU<span class="divider"></span></span></li>
                                                    <li><span class="strong" style={{fontSize:"26px"}}>Control cabinet Industrial PC<span class="divider">: </span></span></li>

                                                </ul>
                                            </div>

                                            <div class="single-split-text section-space--bottom--40">
                                                <h3>Features:</h3>
                                                <ol>
                                                    <li>Support Intel® Core™ 6th/7th/8th generation i7/i5/i3/pentium/Celeron high performance processors，Max.65W TDP</li>
                                                    <li>8 x Intel Gbe LAN controller</li>
                                                    <li>4 x USB3.0, USB2.0 Type A on board for dongle</li>
                                                    <li>2 x COM,1 x RS-232/485, 1 x RS-485</li>
                                                    <li>Support VGA and HDMI display port</li>
                                                    <li>8 x DI, 8 x DO (isolated)</li>
                                                    <li>Supports mSATA and 2.5” SATA hard disk storage</li>
                                                    <li>DC12~24V Power supply with overcurrent, overvoltage and polarity inverse protection</li>
                                                    <li>Book style and wall-mounted, small size(200mm x 154.5mm x 74.6mm)</li>
                                                    <li>Fully enclosed structure, sturdy aluminum profile chassis with fan embedded to get a better cooling system</li>
                                                    <li>Wide operating temperature -20 ~ 60℃ supported</li>

                                                </ol>
                                            </div>

                                            <div class="part-img col-xl-12 col-lg-12 col-md-12">

                                                <img src="assets/images/product/p_5_texas_automations.jpg" alt="image" />
                                            </div>
                                            <div>
                                                <img src="assets/images/product/p_6_texas_automations.jpg" alt="image" />
                                            </div>




                                            <style>
                                                {/* .corg {
                                        width: 15%;
                                        text-align: center;
                                        background-color: #EE5935;
                                    }

                                    .cgre {
                                        text-align: center;
                                        background-color: #00215B;
                                        color:white !important;
                                    }

                                    .cwht {
                                        padding-left: 10px;
                                        padding-right: 10px;
                                    }

                                    td {
                                        color: black !important;
                                    } */}
                                            </style>

                                            <h3>Specification:</h3>
                                            <table>

                                                <tr>
                                                    <td colspan="2" style={{ width: "30%", textAlign: "center", color: "black" }}><b>Model Name</b></td>
                                                    <td style={{ textAlign: "center", color: "black" }}><b>SP-6162</b></td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="2" class="corg"><b>Processor</b></td>
                                                    <td class="cgre"><b>CPU</b></td>
                                                    <td class="cwht">Intel® Core™ 6/7/8代 i7/i5/i3/pentium/celeron LGA1151 type CPU. Max.65W TDP</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>BIOS</b></td>
                                                    <td class="cwht">AMI UEFI 128Mbit</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="2" class="corg"><b>Memory</b></td>
                                                    <td class="cgre"><b>DIMM</b></td>
                                                    <td class="cwht">2 x 260-pin SODIMM</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>Capacity</b></td>
                                                    <td class="cwht">DDR4-2400MHz，8GB pre-installed(system max: 32GB)</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="2" class="corg"><b>Storage</b></td>
                                                    <td class="cgre"><b>SSD</b></td>
                                                    <td class="cwht">1 x Full-size PCIe Mini slot support mSATA</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>SATA</b></td>
                                                    <td class="cwht">1 x 2.5“ SATA bay </td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="2" class="corg"><b>I/O</b></td>
                                                    <td class="cgre"><b>USB</b></td>
                                                    <td class="cwht">4 x USB3.0(USB2.0 compliant), 1 x USB2.0 Type A on the board for USB dongle</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>COM</b></td>
                                                    <td class="cwht">2 x COM(DB-9), 1 x selectable to support RS-232 or 485 mode by the switch, RS-485 support auto flow control，1 x RS-485</td>
                                                </tr>

                                                <tr>
                                                    <td rowspan="8" class="corg"><b>Ethernet</b></td>
                                                    <td class="cgre"><b>LAN1</b></td>
                                                    <td class="cwht">Intel 10/100/1000Mbps controller</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>LAN2</b></td>
                                                    <td class="cwht">Intel 10/100/1000Mbps controller</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>LAN3</b></td>
                                                    <td class="cwht">Intel 10/100/1000Mbps controller</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>LAN4</b></td>
                                                    <td class="cwht">Intel 10/100/1000Mbps controller</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>LAN5</b></td>
                                                    <td class="cwht">Intel 10/100/1000Mbps controller</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>LAN6</b></td>
                                                    <td class="cwht">Intel 10/100/1000Mbps controller</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>LAN7</b></td>
                                                    <td class="cwht">Intel 10/100/1000Mbps controller</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>LAN8</b></td>
                                                    <td class="cwht">Intel 10/100/1000Mbps controller</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="2" class="corg"><b>Display</b></td>
                                                    <td class="cgre"><b>VGA</b></td>
                                                    <td class="cwht">Support up to 1920 x 1200@60Hz</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>HDMI</b></td>
                                                    <td class="cwht">Support up to 3840 x 2160@60Hz</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="2" class="corg"><b>DIO</b></td>
                                                    <td class="cgre"><b>DI</b></td>
                                                    <td class="cwht">8 x DI, isolated </td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>DO</b></td>
                                                    <td class="cwht">8 x DO, OC gate output, Imax:0.5A per channel</td>
                                                </tr>


                                                <tr>
                                                    <td class="corg"><b>Other</b></td>
                                                    <td class="cgre"><b>Watch Dog</b></td>
                                                    <td class="cwht">0~255 seconds programmable</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="2" class="corg"><b>Power</b></td>
                                                    <td class="cgre"><b>Voltage Input</b></td>
                                                    <td class="cwht">DC12~24V ±10%, overcurrent, overvoltage and polarity inverse protection</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>Power Consumption</b></td>
                                                    <td class="cwht">Typical:30W ,180W in maximum</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="3" class="corg"><b>Chassis</b></td>
                                                    <td class="cgre"><b>Structure</b></td>
                                                    <td class="cwht">Aluminum-magnesium alloy BOX with fan embedded to assist cooling, Wall-mounted</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>Dimensions</b></td>
                                                    <td class="cwht">(L)200mm x (W)154.5mm x (H)74.6mm</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>Net Weight</b></td>
                                                    <td class="cwht">2.4 Kg</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="5" class="corg"><b>Reliability</b></td>
                                                    <td class="cgre"><b>Work Temperature</b></td>
                                                    <td class="cwht">-20ºC ~ 60ºC (-4℉~140℉) with air flow (mSATA), 5~95% (Non-condensing)</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>Storage Temperature</b></td>
                                                    <td class="cwht">-40ºC ~ 80ºC (-40 ~ 176℉) with air flow (msATA), 5~95% (Non-condensing)</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>Operating Vibration</b></td>
                                                    <td class="cwht">SSD : 1.5 Grms, IEC 60068-2-64, random, 5 ~ 500 Hz, 1 hr/axis</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>Operating Shock</b></td>
                                                    <td class="cwht">SSD applied: 10 G, IEC 60068-2-64, half-sine wave, 11ms duration</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>EMC</b></td>
                                                    <td class="cwht">CE/FCC Class A</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="2" class="corg"><b>OS</b></td>
                                                    <td class="cgre"><b>Windows</b></td>
                                                    <td class="cwht">Windows 10 IoT</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre"><b>Linux</b></td>
                                                    <td class="cwht">Ubuntu</td>
                                                </tr>


                                            </table>

                                            <br />
                                            <br />



                                            <div class="single-split-text section-space--bottom--40">
                                                <h3>Dimensions(Unit: mm)</h3>
                                                <img width="70%" src="assets/images/product/p_9_texas_automations.jpg" />
                                            </div>


                                            <br />
                                            <br />



                                            {/* <!--<h3 class="title">Ordering Information:</h3>--> */}

                                            <style>
                                                {/* .headleft {
                                        width: 20%;
                                        color: black;
                                        text-align: center;
                                        background-color: #00215B;
                                        color: white !important;
                                    }

                                    .headright {
                                        color: black;
                                        text-align: center;
                                        background-color: #00215B;
                                        color: white !important;
                                    }

                                    .colleft {
                                        text-align: center;
                                        background-color: #EE5935;
                                    }

                                    .colright {
                                        padding-left: 10px !important;
                                        padding-right: 10px !important;

                                    } */}
                                            </style>

                                            {/* <!--<table>-->

                                <!--    <tr>-->
                                <!--        <td class="headleft"><b>Product Code</b></td>-->
                                <!--        <td class="headright"><b>Configuration</b></td>-->
                                <!--    </tr>-->
                                <!--    <tr>-->
                                <!--        <td class="colleft"><b>NP-6123-L4</b></td>-->
                                <!--        <td class="colright">CPU:NULL, RAM:NULL, mSATA:NULL, SATA:NULL,1 x RS-232/485(Selectable), 1 x RS-485，8 x LAN, 4 x USB3.0，8 x DI，8 x DO，VGA/HDMI display port, DC12~24V Power input</td>-->
                                <!--    </tr>-->
                                <!--</table>--> */}

                                            <br />
                                            <br />


                                            <h3 class="title">Option: </h3>

                                            <table>

                                                <tr>
                                                    <td class="headleft"><b>Parts</b></td>
                                                    <td colspan="2" class="headright"><b>Description</b></td>
                                                </tr>

                                                <tr>
                                                    <td rowspan="4" class="colleft"><b>CPU</b></td>
                                                    <td class="colright">Intel® Pentium</td>
                                                    <td class="colright">G4400</td>
                                                </tr>
                                                <tr>
                                                    <td class="colright">Intel® Core™ Skylake</td>
                                                    <td class="colright">I3-6100,I3-6100T,I5-6400,I5-6400T,I5-6500,I5-6500T,I7-6700,I7-6700T</td>
                                                </tr>
                                                <tr>
                                                    <td class="colright">Intel® Core™ Kaby Lake</td>
                                                    <td class="colright">I3-7100,I5-7400,I5-7500,I7-7700,I7-7700T</td>
                                                </tr>
                                                <tr>
                                                    <td class="colright">Intel® Core™ Coffee Lake</td>
                                                    <td class="colright">I3-8100,I5-8400,I5-8500,I7-8700</td>
                                                </tr>

                                                <tr>
                                                    <td class="colleft"><b>DDR4 Memory</b></td>
                                                    <td colspan="2" class="colright">DDR4-2400MHz , 4GB, 8GB, 16GB</td>
                                                </tr>

                                                <tr>
                                                    <td class="colleft"><b>SSD</b></td>
                                                    <td colspan="2" class="colright">mSATA, 64GB, 128GB, 256GB, 512GB ... ...</td>
                                                </tr>

                                                <tr>
                                                    <td class="colleft"><b>2.5”SDD/HDD</b></td>
                                                    <td colspan="2" class="colright">512GB, 1TB … …</td>
                                                </tr>

                                            </table>



                                            {/* 
                                <!--<div class="text-wrapper">-->
                                <!--        <p>Texas Automation industrial Operation Panels are custom-made with neutral from panel and multiple expansion slots to fit with custom needs in industrial and IOT applications.</p>-->
                                <!--        <ul class="text-feature-list">-->
                                <!--            <li>5+ Year Product Life Cycle</li>-->
                                <!--            <li>Original Design Manufacturer</li>-->
                                <!--            <li>Netherlands Technical Support</li>-->
                                <!--            <li>Netherlands Warranty Service</li>-->
                                <!--        </ul>-->
                                <!--</div>--> */}

                                            <br />

                                            <table style={{ width: "100%" }}>

                                                <style>
                                                    {/* th,
                                        td {
                                            border: 0.5px solid black;
                                        } */}
                                                </style>
                                                {/* <!-- <tr>
                            <th>Type</th>
                            <th>Introduction</th>
                            <th>Panel PC</th>
                            <th>Monitor</th>
                          </tr> --> */}


                                            </table>







                                            {/* <!--<div class="text-wrapper">-->
                                <!--        <p>Texas Automation industrial Operation Panels are custom-made with neutral from panel and multiple expansion slots to fit with custom needs in industrial and IOT applications.</p>-->
                                <!--        <ul class="text-feature-list">-->
                                <!--            <li>5+ Year Product Life Cycle</li>-->
                                <!--            <li>Original Design Manufacturer</li>-->
                                <!--            <li>Netherlands Technical Support</li>-->
                                <!--            <li>Netherlands Warranty Service</li>-->
                                <!--        </ul>-->
                                <!--</div>--> */}

                                            <br />

                                            <table style={{ width: "100%" }}>

                                                <style>
                                                    {/* th,
                                        td {
                                            border: 0.5px solid black;
                                        } */}
                                                </style>
                                                {/* <!-- <tr>
                            <th>Type</th>
                            <th>Introduction</th>
                            <th>Panel PC</th>
                            <th>Monitor</th>
                          </tr> --> */}


                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="cta">
                <div class="container">
                    <div class="row justify-content-between no-gutters">
                        <div class="col-xl-9 col-lg-9 col-md-9">
                            <div class="part-txt">
                                <h2>We are representing <br />SPAN Inspection System PVT. LTD.</h2>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-md-3 d-xl-flex d-lg-flex d-md-flex align-items-center justify-content-end">
                            <Link to="https://spansystems.in/" target="_blank" class="def-btn">Visit Website </Link>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default P3;