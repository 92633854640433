import React from "react";
import { Link } from "react-router-dom";


const About = () => {
    return (
        <>
            <div class="breadcrumb">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-3 col-lg-4">
                            <div class="part-txt">
                                <h1>About Us</h1>
                                <ul>
                                    <li><Link to={"/"}>Home</Link></li>
                                    <li><i class="icofont-double-right"></i></li>
                                    <li class="current">About Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="about" >
                <div class="container">
                    <div class="row justify-content-md-center">
                        <div class="col-xl-6 col-lg-5 col-md-8 d-xl-flex d-lg-flex align-items-center justify-content-end">
                            <div class="part-img">
                                <img src="assets/images/product/about_1_texas_automations.jpg" alt="image" />
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-7 col-md-10 d-xl-flex d-lg-flex align-items-center">
                            <div class="part-txt">
                                <div class="title">
                                    <h4>ABOUT US</h4>
                                    <h2>Texas Automation</h2>
                                </div>
                                <p class="b" style={{ textAlign: "justify", textJustify: "inter-word" }}>At Texas Automation, we are dedicated to comprehending your unique automation requirements and providing tailor-made solutions and services. Our extensive range of industrial PCs is designed to operate seamlessly at your desired speed, even in the most challenging environments. Additionally, we offer top-of-the-line machine vision solutions tailored specifically for the pharmaceutical industry, consistently surpassing expectations.</p>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-sm-6">
                                        <div class="single-box one">
                                            <div class="icon">
                                                <img src="assets/images/about-icon-1_texas_automations.png" alt="icon" />
                                            </div>
                                            <h3 class="txt">Expert Team</h3>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-sm-6">
                                        <div class="single-box two">
                                            <div class="icon">
                                                <img src="assets/images/about-icon-2_texas_automations.png" alt="icon" />
                                            </div>
                                            <h3 class="txt">Fast Service</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* <div class="fun-fact-2">
                <div class="container">
                    <div class="row no-gutters">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-6">
                            <div class="single-box">
                                <div class="txt">
                                    <h2><span data-count="7453">0</span></h2>
                                    <p>Happy Customer</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-6">
                            <div class="single-box">
                                <div class="txt">
                                    <h2><span data-count="6597">0</span></h2>
                                    <p>Country Reached</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-6">
                            <div class="single-box">
                                <div class="txt">
                                    <h2><span data-count="3467">0</span></h2>
                                    <p>Project Complete</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-6">
                            <div class="single-box last">
                                <div class="txt">
                                    <h2><span data-count="698">0</span>+</h2>
                                    <p>Awards Winning</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div class="about about-2 about-inner" style={{ paddingTop: "0px" }}>
                <div class="container">
                    <div class="boxes">
                        <div class="row">

                            <div class="col-xl-6 col-lg-6 col-md-6">
                                <div class="single-box">
                                    <h3>Our Vission</h3>
                                    <p style={{ textAlign: "justify", textJustify: "inter-word" }}>Our vision is to be the preferred choice for automation solutions, setting industry standards for quality and performance. We continuously evolve our product portfolio, harnessing technological advancements to create future-ready solutions that enable seamless automation across various sectors. At Texas Automation, we aspire to be your trusted partner, driving progress through automation.</p>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6">
                                <div class="single-box">
                                    <h3>Our Mission</h3>
                                    <p style={{ textAlign: "justify", textJustify: "inter-word" }}>Our mission is to empower industries with cutting-edge automation solutions, enhancing operational efficiency and reliability. We are committed to delivering high-performance Industrial Panel PCs, Fanless Industrial Computers, and Real-Time Automation Controllers that drive innovation and streamline processes.</p>
                                    &nbsp;

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}
export default About;