import React from "react";
import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const Home = () => {

    return (
        <>
            {/* <OwlCarousel class="banner owl-theme dotClass" items={1} >
                <div class="row">
                    <div class="slider slider-2">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-7 col-lg-8 col-md-7">
                                    <div class="banner-txt" style={{ width: "100%" }}>
                                        <h1><span>We</span> deliver the<br />performance you need</h1>
                                        <p>Automation describes a wide range of technologies that reduce human intervention in processes</p>
                                        <Link to={"/about"} class="def-btn">About Us<i class="icofont-double-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slider slider-3">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-7 col-lg-8 col-md-7">
                                    <div class="banner-txt">
                                        <h1><span>Inspection</span> and Serialization Systems</h1>
                                        <p>SPAN deliver amazing experience and 'aha' moment by providing innovative machine vision products</p>
                                        <Link to={"https://spansystems.in/"} class="def-btn">Our Products<i class="icofont-double-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel> */}
            <OwlCarousel className='banner owl-theme' loop margin={10} items={1} dots={false} autoplay={true} autoplayTimeout={5000}>
                <div class="slider slider-2">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-7 col-lg-8 col-md-7">
                                <div class="banner-txt" style={{ width: "100%" }}>
                                    <h1><span>We</span> deliver the<br />performance you need</h1>
                                    <p>Automation describes a wide range of technologies that reduce human intervention in processes</p>
                                    <Link to={"/about"} class="def-btn">About Us<i class="icofont-double-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="slider slider-3">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-7 col-lg-8 col-md-7">
                                <div class="banner-txt">
                                    <h1><span>Inspection</span> and Serialization Systems</h1>
                                    <p>SPAN deliver amazing experience and 'aha' moment by providing innovative machine vision products</p>
                                    <Link to={"https://spansystems.in/"} class="def-btn">Our Products<i class="icofont-double-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </OwlCarousel>



            <div class="about">
                <div class="container">
                    <div class="row justify-content-md-center">
                        <div class="col-xl-6 col-lg-5 col-md-8 d-xl-flex d-lg-flex align-items-center justify-content-end">
                            <div class="part-img">
                                <img src="assets/images/product/about_1_texas_automations.jpg" alt="image" />
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-7 col-md-10 d-xl-flex d-lg-flex align-items-center">
                            <div class="part-txt">
                                <div class="title">
                                    <h4>ABOUT US</h4>
                                    <h2>Texas Automation</h2>
                                </div>
                                <p class="b" style={{ textAlign: "justify", textJustify: "inter-word" }}>We carefully understand your automation needs and deliver the best possible solution and services you need. We have a range of industrial PC which can perform at your required speed and sustain tough environments. We have some of the best in class machine vision solutions for pharmaceutical industry which can outperform your expectations</p>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-sm-6">
                                        <div class="single-box one">
                                            <div class="icon">
                                                <img src="assets/images/about-icon-1_texas_automations.png" alt="icon" />
                                            </div>
                                            <h3 class="txt">Expert Team</h3>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-sm-6">
                                        <div class="single-box two">
                                            <div class="icon">
                                                <img src="assets/images/about-icon-2_texas_automations.png" alt="icon" />
                                            </div>
                                            <h3 class="txt">Fast Service</h3>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--<p>We are problem solvers. Fueled by creativity, technical expertise and tenacity, we push the boundaries of what is to achieve what is needed. Our mission is to solve your most difficult compute challenges with solutions tailored to your specific needs and the flexibility to evolve for long-term and performance.</p>--> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="service">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-6 col-md-8">
                            <div class="heading">
                                <h2>Our Products</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12">
                            <OwlCarousel className='service-slider owl-theme dotClass' loop margin={30} items={3} autoplay={true}
                                responsive={{
                                    // For smaller screens (e.g., mobile devices)
                                    0: {
                                        items: 1, // Show 2 items on smaller screens
                                    },
                                    768: {
                                        items: 2, // Show 2 items on smaller screens
                                    },
                                    // For medium-sized screens (e.g., tablets)
                                    1400: {
                                        items: 3, // Show 3 items on tablets
                                    },
                                    // You can add more breakpoints and item counts as needed
                                }}

                                autoplayTimeout={5000} animateIn={'slide-in'}  dots={false}>
                                <div class="single-box">
                                    <div class="part-img">
                                        <img src="assets/images/product/p_1_texas_automations.jpg" alt="image" />
                                        <div class="overlay">
                                            <Link to={"/industrial_panel_pc"}>+</Link>
                                        </div>
                                    </div>
                                    <div class="part-txt">
                                        <Link to={"/industrial_panel_pc"}>Industrial<br />Panel PC</Link>
                                    </div>
                                </div>
                                <div class="single-box">
                                    <div class="part-img">
                                        <img src="assets/images/product/p_4_texas_automations.jpg" alt="image" />
                                        <div class="overlay">
                                            <Link to={"/fanless_industrial_computer"}>+</Link>
                                        </div>
                                    </div>
                                    <div class="part-txt">
                                        <Link to={"/fanless_industrial_computer"}>Industrial<br />Computer</Link>
                                    </div>
                                </div>
                                <div class="single-box">
                                    <div class="part-img">
                                        <img src="assets/images/product/p_8_texas_automations.jpg" alt="image" />
                                        <div class="overlay">
                                            <Link to={"/real_time_automation"}>+</Link>
                                        </div>
                                    </div>
                                    <div class="part-txt">
                                        <Link to={"/real_time_automation"}>Automation<br />Controller</Link>
                                    </div>
                                </div>

                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>





            <div class="blog">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-6">
                            <div class="heading">
                                <h2>Product of SPAN</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-6">
                            <div class="single-box">
                                <div class="part-img">
                                    <img href="https://spansystems.in/countbeat/" src="assets/images/countbeat_texas_automations.jpg" alt="image" />
                                </div>
                                <div class="part-txt">
                                    <div class="blog-info">
                                        <div class="date">
                                            <span>Countbeat</span>
                                        </div>
                                        <div class="admin">
                                            <span>By: <span class="name">SPAN</span></span>
                                        </div>
                                    </div>
                                    <p href="https://spansystems.in/countbeat/">Electronic Counting Machine - Countbeat</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6">
                            <div class="single-box">
                                <div class="part-img">
                                    <img href="https://spansystems.in/codebeat/" src="assets/images/codebeat_texas_automations.jpg" alt="image" />
                                </div>
                                <div class="part-txt">
                                    <div class="blog-info">
                                        <div class="date">
                                            <span>Codebeat</span>
                                        </div>
                                        <div class="admin">
                                            <span>By: <span class="name">SPAN</span></span>
                                        </div>
                                    </div>
                                    <p href="https://spansystems.in/codebeat/">Versatile Code Reader - Codebeat</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6">
                            <div class="single-box">
                                <div class="part-img">
                                    <img href="https://spansystems.in/blisbeat-hs/" src="assets/images/blisbeat_texas_automations.jpg" alt="image" />
                                </div>
                                <div class="part-txt">
                                    <div class="blog-info">
                                        <div class="date">
                                            <span>Blisbeat</span>
                                        </div>
                                        <div class="admin">
                                            <span>By: <span class="name">SPAN</span></span>
                                        </div>
                                    </div>
                                    <p href="https://spansystems.in/blisbeat-hs/">Blister Inspection System - Blisbeat</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6">
                            <div class="single-box">
                                <div class="part-img">
                                    <img href="https://spansystems.in/traybeat/" src="assets/images/traybeat_texas_automations.png" alt="image" />
                                </div>
                                <div class="part-txt">
                                    <div class="blog-info">
                                        <div class="date">
                                            <span>Traybeat</span>
                                        </div>
                                        <div class="admin">
                                            <span>By: <span class="name">SPAN</span></span>
                                        </div>
                                    </div>
                                    <p href="https://spansystems.in/traybeat/">Container Counting Machine - Traybeat</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}
export default Home