import React from "react"
import { Link } from "react-router-dom";


const Footer = () => {
    return (
        <>


            <div class="footer">
                <div class="main-footer">
                    <div class="container">
                        <div class="row no-gutters">

                            <div class="col-xl-4 col-lg-4 col-sm-6">
                                <div class="site-info">
                                    <div class="logo">
                                        <img src="assets/images/product/texas_automations_logo.png" alt="logo"/>
                                    </div>
                                    <div class="social">
                                        <Link href={"/"}><i class="icofont-facebook"></i></Link>
                                        <Link href={"/"}><i class="icofont-twitter"></i></Link>
                                        <Link href={"/"}><i class="icofont-skype"></i></Link>
                                        <Link href={"/"}><i class="icofont-dribbble"></i></Link>
                                    </div>
                                </div>
                            </div>


                            <div class="col-xl-3 col-lg-3 col-sm-6">
                                <div class="footer-service">
                                    <h3 class="title">Product</h3>
                                    <ul>
                                        <li><Link to={"/industrial_panel_pc"}><span><i class="icofont-double-right"></i></span>Industrial Panel PC</Link></li>
                                        <li><Link to={"/fanless_industrial_computer"}><span><i class="icofont-double-right"></i></span>Fanless Industrial Computer</Link></li>
                                        <li><Link to={"/real_time_automation"}><span><i class="icofont-double-right"></i></span>Real Time Automation Controller</Link></li>

                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-1 col-lg-1">
                            </div>
                            <div class="col-xl-3 col-lg-3 col-sm-6">
                                <div class="footer-contact">
                                    <h3 class="title">Contact</h3>
                                    <ul>

                                        <li>TEXAS AUTOMATION SERVICES LLC</li>
                                        <li><span><i class="icofont-google-map"></i></span>7162 HARCOURT XING INDIAN LAND, SC 29707-5841</li>
                                        <li><span><i class="icofont-email"></i></span>info@texasautomations.com</li>

                                    </ul>
                                </div>
                            </div>

                            <div class="col-xl-1 col-lg-1">
                            </div>

                        </div>
                    </div>
                </div>
                <div class="copyright">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-xl-6 col-lg-6 col-md-6">
                                <p>All Rights Reserved by Texas @ 2023 | Managed by GuruSoftware</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Footer;