import React from "react"
import { Link } from "react-router-dom";


const P2 = () => {
    return (
        <>


            <div class="breadcrumb">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-6 col-lg-7">
                            <div class="part-txt">
                                <h1>Fanless Industrial Computer</h1>
                                <ul>
                                    <li><Link to={"/"}>Home</Link></li>
                                    <li><i class="icofont-double-right"></i></li>
                                    <li class="current">Fanless Industrial Computer</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="service-details">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-xl-3 col-lg-4 col-md-5">
                            <div class="sidebar">
                                <h2>Product Category</h2>
                                <div class="nav flex-column nav-pills three"  >
                                    <Link class="nav-link " to={"/industrial_panel_pc"}><span><i class="icofont-double-right"></i></span>Industrial Panel PC</Link>
                                    <Link class="nav-link active" to={"/fanless_industrial_computer"}><span><i class="icofont-double-right"></i></span>Fanless Industrial Computer</Link>
                                    <Link class="nav-link " to={"/real_time_automation"}><span><i class="icofont-double-right"></i></span>Real Time Automation</Link>
                                    {/* <!-- <Link class="nav-link " to={"/industrial_panel_pc"} role="tab" aria-controls="v-pills-one" aria-selected="false"><span><i class="icofont-double-right"></i></span>Industrial Panel PC</Link> */}
                                    {/* <Link class="nav-link active" href="product2.php v-pills-two" role="tab" aria-controls="v-pills-two" aria-selected="true"><span><i class="icofont-double-right"></i></span>Fanless Industrial Computer</Link>
                                    <Link class="nav-link" href="product3.php v-pills-three" role="tab" aria-controls="v-pills-three" aria-selected="false"><span><i class="icofont-double-right"></i></span>Real Time Automation</Link> --> */}

                                </div>
                                {/* <h2>Download Brochure</h2>
                                <div class="btns">
                                    <Link href="service-details.html#" class="download-btn">Brochure.PDF<span><i class="icofont-file-pdf"></i></span></Link>
                                    {/* <!-- <Link href="service-details.html#" class="download-btn two">Brochure.DOC<span><i
                                        class="icofont-file-document"></i></span></Link> --> 
                                </div> */}
                            </div>
                        </div>
                        <div class="col-xl-8 col-lg-8 col-md-7 next">
                            <div class="tab-content" id="v-pills-tabContent">
                                <div class="tab-pane fade show active" id="v-pills-one" role="tabpanel" aria-labelledby="v-pills-one-tab">
                                    <div class="main-content">
                                        <div class="row">
                                            <style>
                                                {/* @media only screen and (min-device-width: 481px) {
                                        .aaa {
                                                    padding - left: 80px;
                                                padding-right: 80px;


                                        }
                                    }

                                                @media only screen and (max-device-width: 480px) {

                                        .aaa {

                                                    width: 100% !important;
                                        }
                                    }

                                                .abc {
                                                    color: black !important;
                                    } */}
                                            </style>
                                            <img src="assets/images/product/p_4_texas_automations.jpg" width="100%" alt="image" class="aaa" />
                                        </div>





                                        <div class="part-txt">
                                            <div class="quote" style={{textAlign: "left"}}>
                                                <h2 class="abc">Information : </h2>
                                                <ul>
                                                    <li><span class="strong" style={{fontSize:"26px"}}>Intel® Core™ 6/7/8 gen i7/i5/i3/Pentium/Celeron Desktop High Performance CPU<span class="divider"></span></span></li>
                                                    <li><span class="strong" style={{fontSize:"26px"}}>PoE, DIO and Light control All In One Design<span class="divider"></span></span></li>

                                                </ul>
                                            </div>

                                            <div class="single-split-text section-space--bottom--40">
                                                <h3>Features:</h3>
                                                <ol>
                                                    <li>Support Intel® Core™ 6th/7th/8th generation i7/i5/i3/pentium/Celeron high performance processors，Max.65W TDP</li>
                                                    <li>2 x Intel i210-AT Gbe LAN controller</li>
                                                    <li>4 x USB3.0, USB2.0 Type A on board for dongle </li>
                                                    <li>2 x RS-232/485,RS-485 support auto flow control</li>
                                                    <li>Support DVI and HDMI display port</li>
                                                    <li>1 x miniPCIE  expansion slot，Support Wifi,3G/4G modules</li>
                                                    <li>4 x Intel i210-AT Gbe LAN, PoE, 15w</li>
                                                    <li>4 x PWM light control support external trigger input</li>
                                                    <li>16 x isolated DIO(8 x DI, 8 x DO)</li>
                                                    <li>DC12~24V Power supply with overcurrent, overvoltage and polarity inverse protection</li>
                                                    <li>Fully enclosed structure, no cable design and sturdy aluminum profile chassis with fan embedded to get a better cooling system</li>
                                                    <li>Wide operating temperature -20 ~ 60℃ supported</li>
                                                </ol>
                                            </div>

                                            <div class="part-img col-xl-12 col-lg-12 col-md-12">
                                                <img src="assets/images/product/p_5_texas_automations.jpg" alt="image" />
                                            </div>
                                            <div>
                                                <img src="assets/images/product/p_6_texas_automations.jpg" alt="image" />
                                            </div>


                                            <div>
                                                <img src="assets/images/product/p_7_texas_automations.jpg" alt="image" />
                                            </div>

                                            <style>
                                                {/* .corg {
                                                    width: 15%;
                                                text-align: center;
                                                background-color: #EE5935;
                                    }

                                                .cgre {
                                                    text - align: center;
                                                width: 20%;
                                                background-color: #00215B;
                                                color: white !important;
                                    }

                                                .cwht {
                                                    padding - left: 10px;
                                                padding-right: 10px;
                                    }

                                                td {
                                                    color: black !important;
                                    } */}
                                            </style>

                                            <h3>Specification:</h3>
                                            <table>

                                                <tr>
                                                    <td colspan="2" style={{width: "30%",textAlign:"center",color:"black"}}><b>Model Name</b></td>
                                                    <td style={{textAlign:"center",color:"black"}}><b>SP-6362</b></td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="2" class="corg"><b>Processor</b></td>
                                                    <td class="cgre">CPU</td>
                                                    <td class="cwht">Intel® Core™ 6/7/8th i7/i5/i3/pentium/celeron LGA1151 type CPU. Max.65W TDP</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre">BIOS</td>
                                                    <td class="cwht">AMI UEFI 64Mbit</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="2" class="corg"><b>Memory</b></td>
                                                    <td class="cgre">DIMM</td>
                                                    <td class="cwht">2 x 260-pin SODIMM</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre">Capacity</td>
                                                    <td class="cwht">DDR4-2400MHz，8GB pre-installed(system max: 32GB)</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="2" class="corg"><b>Storage</b></td>
                                                    <td rowspan="2" class="cgre">SSD</td>
                                                    <td class="cwht">1 x Full-size PCIe Mini slot support mSATA</td>
                                                </tr>
                                                <tr>
                                                    <td class="cwht">1 x 2280 size SATA3.0 M.2-KEYB slot</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="2" class="corg"><b>I/O</b></td>
                                                    <td class="cgre">USB</td>
                                                    <td class="cwht">4 x USB3.0(USB2.0 compliant), 1 x USB2.0 Type A on the board for USB dongle</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre">COM</td>
                                                    <td class="cwht">2 x COM(DB-9), optical isolated, selectable to support RS-232 or 485 mode by the switch, RS-485 support auto flow control,(ESD protection for RS-232: Air gap ±8KV, Contact ±6KV)</td>
                                                </tr>

                                                <tr>
                                                    <td rowspan="2" class="corg"><b>Ethernet</b></td>
                                                    <td class="cgre"> LAN1</td>
                                                    <td class="cwht">Intel I210-AT 10/100/1000Mbps controller</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre">LAN2</td>
                                                    <td class="cwht">Intel I210-AT 10/100/1000Mbps controller</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="2" class="corg"><b>Display</b></td>
                                                    <td class="cgre">DVI-D</td>
                                                    <td class="cwht">Support up to 1920 x 1080@60Hz</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre">HDMI</td>
                                                    <td class="cwht">Support up to 3840 x 2160@60Hz</td>
                                                </tr>


                                                <tr>
                                                    <td class="corg"><b>Expansions</b></td>
                                                    <td class="cgre">miniPCIe</td>
                                                    <td class="cwht">1 x full size PCIe with SIM holder</td>
                                                </tr>


                                                <tr>
                                                    <td class="corg"><b>PoE</b></td>
                                                    <td class="cgre">PoE LAN</td>
                                                    <td class="cwht">4 x Intel i210-AT PoE Gbe，15W per channel</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="4" class="corg"><b>Light Control</b></td>
                                                    <td class="cgre">DI</td>
                                                    <td class="cwht">8 x DI NPN，isolated 2500 Vrms</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre">DO</td>
                                                    <td class="cwht">8 x DO Imax:0.5A per channel，isolated 2500 Vrms</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre">PWM</td>
                                                    <td class="cwht">4 x PWM Light control，Imax: 1A per channel</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre">External trigger input</td>
                                                    <td class="cwht">4 x External Trigger Input</td>
                                                </tr>


                                                <tr>
                                                    <td class="corg"><b>Other</b></td>
                                                    <td class="cgre">Watch Dog</td>
                                                    <td class="cwht">0~255 seconds programmable</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="2" class="corg"><b>Power</b></td>
                                                    <td class="cgre">Voltage Input</td>
                                                    <td class="cwht">DC12~24V ±10%, overcurrent, overvoltage and polarity inverse protection, Must be DC24V input in the case of using light control functionalities</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre">Power Consumption</td>
                                                    <td class="cwht">Typical:30W ，250W in maximum</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="3" class="corg"><b>Chassis</b></td>
                                                    <td class="cgre">Structure</td>
                                                    <td class="cwht"> Aluminum-magnesium alloy BOX with fan embedded to assist cooling, Wall-mounted</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre">Dimensions</td>
                                                    <td class="cwht">(L)200mm x (W)154.5mm x (H)74.6mm</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre">Net Weight</td>
                                                    <td class="cwht">2.4 Kg</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="5" class="corg"><b>Reliability</b></td>
                                                    <td class="cgre">Work Temperature</td>
                                                    <td class="cwht">-20ºC ~ 60ºC (-4℉~140℉) with air flow (mSATA), 5~95% (Non-condensing)</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre">Storage Temperature</td>
                                                    <td class="cwht">-40ºC ~ 80ºC (-40 ~ 176℉) with air flow (msATA), 5~95% (Non-condensing)</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre">Operating Vibration</td>
                                                    <td class="cwht">SSD : 1.5 Grms, IEC 6006 x -2-64, random, 5 ~ 500 Hz, 1 hr/axis</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre">Operating Shock</td>
                                                    <td class="cwht">SSD applied: 10 G, IEC 6006 x -2-64, half-sine wave, 11ms duration</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre">EMC</td>
                                                    <td class="cwht">CE/FCC Class A</td>
                                                </tr>


                                                <tr>
                                                    <td rowspan="2" class="corg"><b>OS</b></td>
                                                    <td class="cgre">Windows</td>
                                                    <td class="cwht">Windows 10 IoT</td>
                                                </tr>
                                                <tr>
                                                    <td class="cgre">Linux</td>
                                                    <td class="cwht">Ubuntu</td>
                                                </tr>


                                            </table>


                                            <br />
                                            <br />

                                            <style>
                                                {/* .headleft {
                                                    width: 30%;
                                                color: black;
                                                text-align: center;
                                                background-color: #00215B;
                                                color:white !important;
                                    }

                                                .headright {
                                                    color: black;
                                                text-align: center;
                                                background-color: #00215B;
                                                color:white !important;
                                    }

                                                .colleft {
                                                    text - align: center;
                                                background-color: #EE5935;
                                    }

                                                .colright {
                                                    padding - left: 10px !important;
                                                padding-right: 10px !important;

                                    } */}
                                            </style>

                                            {/* <!--<h3 class="title">Ordering Information:</h3>--> */}




                                            <br />
                                            <br />


                                            <style>
                                                {/* .hl {
                                                    width: 20%;
                                                color: black;
                                                text-align: center;
                                                background-color: #00215B;
                                                color:white !important;
                                    }

                                                .hr {
                                                    color: black;
                                                text-align: center;
                                                background-color: #00215B;
                                                color:white !important;
                                    }

                                                .cl {
                                                    text - align: center;
                                                background-color: #EE5935;
                                    }

                                                .cr {
                                                    padding - left: 10px !important;
                                                padding-right: 10px !important;

                                    } */}
                                            </style>

                                            <h3 class="title">Option: </h3>

                                            <table>

                                                <tr>
                                                    <td class="hl"><b>Parts</b></td>
                                                    <td colspan="2" class="hr"><b>Description</b></td>
                                                </tr>

                                                <tr>
                                                    <td rowspan="4" class="cl"><b>CPU</b></td>
                                                    <td class="cr">Intel® Pentium</td>
                                                    <td class="cr">G4400</td>
                                                </tr>
                                                <tr>
                                                    <td class="cr">Intel® Core™ Skylake</td>
                                                    <td class="cr">I3-6100,I3-6100T,I5-6400,I5-6400T,I5-6500,I5-6500T,I7-6700,I7-6700T</td>
                                                </tr>
                                                <tr>
                                                    <td class="cr">Intel® Core™ Kaby Lake</td>
                                                    <td class="cr">I3-7100,I5-7400,I5-7500,I7-7700,I7-7700T</td>
                                                </tr>
                                                <tr>
                                                    <td class="cr">Intel® Core™ Coffee Lake</td>
                                                    <td class="cr">I3-8100,I5-8400,I5-8500,I7-8700</td>
                                                </tr>

                                                <tr>
                                                    <td class="cl"><b>DDR4 Memory</b></td>
                                                    <td colspan="2" class="cr">DDR4-2400MHz , 4GB, 8GB, 16GB</td>
                                                </tr>

                                                <tr>
                                                    <td class="cl"><b>SSD</b></td>
                                                    <td colspan="2" class="cr">mSATA, 64GB, 128GB, 256GB, 512GB</td>
                                                </tr>

                                                <tr>
                                                    <td class="cl"><b>Wifi</b></td>
                                                    <td colspan="2" class="cr">miniPCIE WIFI Set (7260HMWBN) ,Cylindrical antenna</td>
                                                </tr>

                                                <tr>
                                                    <td class="cl"><b>4G set, EC20 3G Modules</b></td>
                                                    <td colspan="2" class="cr">mini PCIE，Support GSM、WCDMA、CDMA</td>
                                                </tr>

                                            </table>






                                            <br />

                                            <table style={{width:"100%"}}>

                                                <style>
                                                    {/* th,
                                                    td {
                                                        border: 0.5px solid black;
                                        } */}
                                                </style>
                                                {/* <!-- <tr>
                                                    <th>Type</th>
                                                    <th>Introduction</th>
                                                    <th>Panel PC</th>
                                                    <th>Monitor</th>
                                                </tr> --> */}


                                            </table>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="cta">
                <div class="container">
                    <div class="row justify-content-between no-gutters">
                        <div class="col-xl-9 col-lg-9 col-md-9">
                            <div class="part-txt">
                                <h2>We are representing <br />SPAN Inspection System PVT. LTD.</h2>
                            </div>
                        </div>
                        <div class="col-xl-2 col-lg-3 col-md-3 d-xl-flex d-lg-flex d-md-flex align-items-center justify-content-end">
                            <Link to="https://spansystems.in/" target="_blank" class="def-btn">Visit Website </Link>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}
export default P2;