import React from "react";
import { Link } from "react-router-dom";

const P1 = () => {
    return(
        <>

<div class="breadcrumb">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-7">
                <div class="part-txt">
                    <h1>Industrial Panel PC</h1>
                    <ul>
                        <li><Link to={"/"}>Home</Link></li>
                        <li><i class="icofont-double-right"></i></li>
                        <li class="current">Industrial Panel PC</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="service-details">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-xl-3 col-lg-4 col-md-5">
                <div class="sidebar">
                <h2>Service Category</h2>
                    
                    <div class="nav flex-column nav-pills three"  >
                    <Link class="nav-link active" to={"/industrial_panel_pc"}><span><i class="icofont-double-right"></i></span>Industrial Panel PC</Link>
                    <Link class="nav-link " to={"/fanless_industrial_computer"}><span><i class="icofont-double-right"></i></span>Fanless Industrial Computer</Link>
                    <Link class="nav-link"  to={"/real_time_automation"}><span><i class="icofont-double-right"></i></span>Real Time Automation</Link>
                    </div>
                    {/* <h2>Download Brochure</h2>
                    <div class="btns">
                        <Link to={"/industrial_panel_pc"} class="download-btn">Brochure.PDF<span><i class="icofont-file-pdf"></i></span></Link>
                        {/* <!-- <Link href="service-details.html#" class="download-btn two">Brochure.DOC<span><i
                                        class="icofont-file-document"></i></span></Link> --> 
                    </div> */}
                </div>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-7 next">
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade show active" id="v-pills-one" role="tabpanel" aria-labelledby="v-pills-one-tab">
                        <div class="main-content">
                            <div class="row">
                                {/* <!-- <div class="part-img col-xl-6 col-lg-6 col-md-6"> --> */}
                                {/* <style>
                                    @media only screen and (min-device-width: 481px) {
                                        .aaa {
                                            padding-left: 80px;
                                            padding-right: 80px;
                                            

                                        }
                                    }

                                    @media only screen and (max-device-width: 480px) {

                                        .aaa {

                                            width: 100% !important;
                                        }
                                    }

                                    .abc{
                                        color: black !important;
                                    }
                                </style> */}
                                <img src="assets/images/product/p_1_texas_automations.jpg" width="100%" alt="image" class="aaa"/>
                                


                            </div>

                            <div class="part-txt">


                                <div class="quote" style={{textAlign: "left"}}>
                                    {/* <!-- <div class="information"> --> */}
                                    <h2 class="abc" >Information :</h2>
                                    <ul>
                                        <li><span class="strong">21.5" Full HD high-performance industrial Panel PC </span></li>
                                        <li><span class="strong">Support Intel® the 6/7/8th generation Core™i3/i5/i7, Pentium, Celeron CPU <span class="divider"></span></span></li>
                                        <li><span class="strong">LGA1151 socket type CPU. Max.65W TDP <span class="divider"></span></span></li>
                                        {/* <!--<li><span class="strong">Budget <span class="divider">: $586.00</span></span></li>-->
                                        <!--<li><span class="strong">Location <span class="divider">: 8468, Andrision, 235 USA.</span></span></li>--> */}
                                    </ul>

                                </div>
                                {/* <style>
                                    .corg {
                                        width: 15%;
                                        text-align: center;
                                        background-color: #EE5935;
                                        
                                    }

                                    .cgre {
                                        text-align: center;
                                        background-color: #00215B;
                                        color:white !important;
                                    }

                                    .cwht {
                                        padding-left: 10px
                                    }

                                    td {
                                        color: black !important;
                                    }
                                </style> */}

                                <table>
                                    <h4 class="title"><b> Specification:</b></h4>

                                    <tr>
                                        <td rowspan="6" class="corg"><b>System</b></td>
                                        <td class="cgre">CPU</td>
                                        <td class="cwht">Intel® Core™ 6/7/8th generation i7/i5/i3, Pentium, Celeron LGA1151 socket type CPU. Max.65W </td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">BIOS</td>
                                        <td class="cwht">AMI UEFI 128Mbit</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Chipset</td>
                                        <td class="cwht">H110</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Memory</td>
                                        <td class="cwht">DDR4-2400MHz，MAX support 64GB</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Storage</td>
                                        <td class="cwht">1 x M.2 (M Key 2280 PCIe Gen3x4 Lane), + 1 x mSATA</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">OS</td>
                                        <td class="cwht">Support Windows 7, Windows 7 Embedded, Windows8, Windows10, Ubuntu, VXWORKS, QNX</td>
                                    </tr>

                                    <tr>
                                        <td rowspan="5" class="corg"><b>I/O</b></td>
                                        <td class="cgre">Network</td>
                                        <td class="cwht">3 x Intel I211 -AT 10/100/1000Mbps controller</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">USB</td>
                                        <td class="cwht">4 x USB3.0 / 2 x USB2.0</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Serial port</td>
                                        <td class="cwht">4 x RS-232 / RS-485 Interface with surge protection, RS485 support automatic flow control</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Expansion Slot</td>
                                        <td class="cwht">1 x Mini-PCIe slot</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">I/O port</td>
                                        <td class="cwht">8DI+8DO，Wet contact input（24V）, OC gate output（24V 300mA）</td>
                                    </tr>

                                    <tr>
                                        <td rowspan="3" class="corg"><b>Physical Characteristics</b></td>
                                        <td class="cgre">Dimension</td>
                                        <td class="cwht">550mm * 342mm * 74mm</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Cut out size</td>
                                        <td class="cwht">533mm * 325mm</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Weight</td>
                                        <td class="cwht">6.9kg</td>
                                    </tr>

                                    <tr>
                                        <td rowspan="2" class="corg"><b>Power</b></td>
                                        <td class="cgre">Input voltage</td>
                                        <td class="cwht">12-24VDC ±10%, Support reverse connection protection, over voltage protection, over current protection</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Idle mode Power Consumption</td>
                                        <td class="cwht">35Watt</td>
                                    </tr>

                                    <tr>
                                        <td rowspan="10" class="corg"><b>LCD</b></td>
                                        <td class="cgre">LCD Type</td>
                                        <td class="cwht">21.5" Full HD TFT</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Resolution</td>
                                        <td class="cwht">1920 x 1080</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Colors</td>
                                        <td class="cwht">16.7MB</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Active Area (W x H)</td>
                                        <td class="cwht">476.64mm x 268.11mm (18.77 x 10.56")</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Backlight</td>
                                        <td class="cwht">LED</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">MTBF (hour)</td>
                                        <td class="cwht">30000hrs</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Pixel Pitch (H x V)</td>
                                        <td class="cwht">0.248 x 0.248</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Luminance</td>
                                        <td class="cwht">250cd/m2</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Contrast Ratio</td>
                                        <td class="cwht">3000 : 1</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Viewing Angle</td>
                                        <td class="cwht">(L) 89 / (R) 89 / (T) 89 / (B) 89</td>
                                    </tr>

                                    <tr>
                                        <td rowspan="6" class="corg"><b>Touch Screen</b></td>
                                        <td class="cgre">Touch Screen Type</td>
                                        <td class="cwht">Capacitive Touchscreen</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Transmittance</td>
                                        <td class="cwht">≥ 87%</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Response Time (touch pen)</td>
                                        <td class="cwht">5ms</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Surface Hardness</td>
                                        <td class="cwht">Mohs Hardness 7H</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Click Life</td>
                                        <td class="cwht">250g / 100,000,000 times</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Multi-touch</td>
                                        <td class="cwht">10 Points</td>
                                    </tr>

                                    <tr>
                                        <td rowspan="7" class="corg"><b>Environment</b></td>
                                        <td class="cgre">Operating Temperature</td>
                                        <td class="cwht">0 ~ 50°C </td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Storage Temperature</td>
                                        <td class="cwht">-20 ~ 60° C </td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Relative Humidity</td>
                                        <td class="cwht">5~95%@40°C (Non-condensing)</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Vibration</td>
                                        <td class="cwht">Operating Random Vibration Test 5~500Hz, 1.5Grms@with SSD, follow IEC 60068-2-64</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Shock</td>
                                        <td class="cwht">Operating 10G peak acceleration (11ms duration), follow IEC 60068-2-27</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">EMC</td>
                                        <td class="cwht">CE/FCC Class A</td>
                                    </tr>
                                    <tr>
                                        <td class="cgre">Front Panel Protection</td>
                                        <td class="cwht">IP65 compliant</td>
                                    </tr>


                                </table>

                                <br/>
                                <br/>

                                <img width="100%" src="assets/images/product/p_3_texas_automations.jpg"/>
                                <h4 class="title">Dimension:- Unit：550mm * 342mm * 74mm </h4>

                                <br/>
                                <br/>



                                {/* <!--<h4 class="title"><b>Ordering Information:</b></h4>--> */}

                                {/* <style>
                                    .headleft {
                                        width: 20%;
                                        color: black;
                                        text-align: center;
                                        background-color: #00215B;
                                        color: white !important;
                                    }

                                    .headright {
                                        color: black;
                                        text-align: center;
                                        background-color: #00215B;
                                        color: white !important;
                                    }

                                    .colleft {
                                        text-align: center;
                                        background-color: #EE5935;
                                    }

                                    .colright {
                                        padding-left: 10px !important;

                                    }
                                </style> */}






                                <br/>
                                <br/>

                                <h4 class="title"><b>Option Items:</b></h4>


                                <table>

                                    <tr>
                                        <td style={{width: "30%",color:"black",textAlign:"center"}} class="cgre"><b>Option items</b></td>
                                        <td style={{color:"black",textAlign:"center"}} class="cgre"><b>Specification</b></td>
                                    </tr>

                                    <tr>
                                        <td rowspan="10" style={{textAlign:"center"}} class="corg"><b>CPU</b></td>
                                        <td class="cwht">Support 6th/7th/8th generation Intel® Core™ i7/i5/i3 LGA socket type processors</td>
                                    </tr>
                                    <tr>
                                        <td class="cwht">Intel® Core™ i3-6100, Dual Core, 3.7GHz, 3MB Cache</td>
                                    </tr>
                                    <tr>
                                        <td class="cwht">Intel® Core™ i3-7100, Dual Core, 3.9GHz, 3MB Cache</td>
                                    </tr>
                                    <tr>
                                        <td class="cwht">Intel® Core™ i3-8100, Quad Core, 3.6GHz, 6MB Cache</td>
                                    </tr>
                                    <tr>
                                        <td class="cwht">Intel® Core™ i5-6400, Quad Core, 2.7GHz, 6MB Cache</td>
                                    </tr>
                                    <tr>
                                        <td class="cwht">Intel® Core™ i5-6500, Quad Core, 3.2GHz, 6MB Cache</td>
                                    </tr>
                                    <tr>
                                        <td class="cwht">Intel® Core™ i5-6500T, Quad Core, 2.5GHz, 6MB Cache</td>
                                    </tr>
                                    <tr>
                                        <td class="cwht">Intel® Core™ i5-8400, Six Cores, 2.8GHz, 9MB Cache</td>
                                    </tr>
                                    <tr>
                                        <td class="cwht">Intel® Core™ i7-6700T, Quad Core, 2.8GHz, 8MB Cache</td>
                                    </tr>
                                    <tr>
                                        <td class="cwht">Intel® Core™ i7-8700, Six Cores, 3.2GHz, 12MB Cache</td>
                                    </tr>

                                    <tr>
                                        <td style={{textAlign:"center"}} class="corg"><b>Memory</b></td>
                                        <td class="cwht">DDR4 4G/8G/16G/32G</td>
                                    </tr>

                                    <tr>
                                        <td style={{textAlign:"center"}} class="corg"><b>Storage</b></td>
                                        <td class="cwht">Mini SATA SSD64G/128G/256G/512G</td>
                                    </tr>

                                    <tr>
                                        <td style={{textAlign:"center"}} class="corg"><b>2010300008</b></td>
                                        <td class="cwht">120Watt Adapter (Input: 100-240V~50-60Hz 2.0 MAX, Output: +12V—10A)</td>
                                    </tr>

                                    <tr>
                                        <td style={{textAlign:"center"}} class="corg"><b>2030100012</b></td>
                                        <td class="cwht">Option EU,US,UK…</td>
                                    </tr>

                                    <tr>
                                        <td style={{textAlign:"center"}} class="corg"><b>5040200002</b></td>
                                        <td class="cwht">7260 series (mini PCIe)</td>
                                    </tr>

                                    <tr>
                                        <td style={{textAlign:"center"}} class="corg"><b>4G module</b></td>
                                        <td class="cwht">(Quectel EC20,EC25,EG25.. )</td>
                                    </tr>

                                </table>

                            

                                <br/>

                                <table style={{width:"100%"}}>

                                    {/* <style>
                                        tr {
                                            border: 0.5px solid black;

                                        }
                                    </style> */}
                                  

                                </table>
                                
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-two" role="tabpanel" aria-labelledby="v-pills-two-tab">
                        <div class="main-content">
                            <div class="part-img">
                                <img src="assets/images/service-details-2_texas_automations.jpg" alt="image"/>
                            </div>
                            <div class="part-txt">
                                <h2>Start bridge construction planning</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged. It was popularised in the 196 with
                                    the release Letraset sheets containine and more recently with desktop publishing
                                    software.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <h3>How to start this service?</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <div class="quote">
                                    <span>Lorem is simply dummy and text of the <br/>printing and typesetting
                                        industry has been industry standard dummy text ever since.</span>
                                </div>
                                <h3>Benefit with this service</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-three" role="tabpanel" aria-labelledby="v-pills-three-tab">
                        <div class="main-content">
                            <div class="part-img">
                                <img src="assets/images/service-details-3_texas_automations.jpg" alt="image"/>
                            </div>
                            <div class="part-txt">
                                <h2>Start Factory construction planning</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged. It was popularised in the 196 with
                                    the release Letraset sheets containine and more recently with desktop publishing
                                    software.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <h3>How to start this service?</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <div class="quote">
                                    <span>Lorem is simply dummy and text of the <br/>printing and typesetting
                                        industry has been industry standard dummy text ever since.</span>
                                </div>
                                <h3>Benefit with this service</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-four" role="tabpanel" aria-labelledby="v-pills-four-tab">
                        <div class="main-content">
                            <div class="part-img">
                                <img src="assets/images/service-details-4_texas_automations.jpg" alt="image"/>
                            </div>
                            <div class="part-txt">
                                <h2>Committed to superior quality and results.</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged. It was popularised in the 196 with
                                    the release Letraset sheets containine and more recently with desktop publishing
                                    software.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <h3>How to start this service?</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <div class="quote">
                                    <span>Lorem is simply dummy and text of the <br/>printing and typesetting
                                        industry has been industry standard dummy text ever since.</span>
                                </div>
                                <h3>Benefit with this service</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-five" role="tabpanel" aria-labelledby="v-pills-five-tab">
                        <div class="main-content">
                            <div class="part-img">
                                <img src="assets/images/service-details-5_texas_automations.jpg" alt="image"/>
                            </div>
                            <div class="part-txt">
                                <h2>Design your home with passion.</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged. It was popularised in the 196 with
                                    the release Letraset sheets containine and more recently with desktop publishing
                                    software.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <h3>How to start this service?</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <div class="quote">
                                    <span>Lorem is simply dummy and text of the <br/>printing and typesetting
                                        industry has been industry standard dummy text ever since.</span>
                                </div>
                                <h3>Benefit with this service</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="v-pills-six" role="tabpanel" aria-labelledby="v-pills-six-tab">
                        <div class="main-content">
                            <div class="part-img">
                                <img src="assets/images/service-details-6_texas_automations.jpg" alt="image"/>
                            </div>
                            <div class="part-txt">
                                <h2>Making your vision come true</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged. It was popularised in the 196 with
                                    the release Letraset sheets containine and more recently with desktop publishing
                                    software.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <h3>How to start this service?</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <div class="quote">
                                    <span>Lorem is simply dummy and text of the <br/>printing and typesetting
                                        industry has been industry standard dummy text ever since.</span>
                                </div>
                                <h3>Benefit with this service</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been industry standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen
                                    book. It has survived not only five centuries, but also the leap into electronic
                                    typese ting, remaining essentially unchanged.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="cta">
    <div class="container">
        <div class="row justify-content-between no-gutters">
            <div class="col-xl-9 col-lg-9 col-md-9">
                <div class="part-txt">
                    <h2>We are representing <br/>SPAN Inspection System PVT. LTD.</h2>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-3 d-xl-flex d-lg-flex d-md-flex align-items-center justify-content-end">
                <Link to="https://spansystems.in" target="_blank" class="def-btn">Visit Website </Link>
            </div>
        </div>
    </div>
</div>


</>
    )
}
export default P1;