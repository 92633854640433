import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import axios from "axios";
import { useRef } from "react";
import { Link } from "react-router-dom";

const Contact = () => {

    const [verified, setVerified] = useState('');
    const [ercapacha, setErcapacha] = useState('')
    const [status, setStatus] = useState('')
    const [visible, setIsVisible] = useState(true);
    const captch = useRef(null);



    const key = '6LdMNSgoAAAAAB98bwLgX5ya0M61hmErbb8nMwkr';

    function onChange() {
        setVerified(true);
    }




    const initalState = {
        names: "",
        email: "",
        message: ""
    };

    const [state, setState] = useState(initalState);
    const { names, email, message } = state;

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };



    const handleSubmit = (e) => {
        // console.log("click")
        e.preventDefault();

        if (verified === true) {
            axios
                .post("https://texasautomations.com/API/texas.php", {
                    names,
                    email,
                    message
                })
                .then(function (data) {
                    console.log(data.data);
                    if (data.data === "Message Sent") {
                        captch.current.reset()
                        setState({ names: "", email: "", message: "" });
                        setStatus("Message sent successfully. We will connect you soon")

                    }
                })
        } else {
            setErcapacha("Please Verify CAPTCHA")
            setIsVisible(true);
            setTimeout(function () {
                setIsVisible(false);
            }, 3000);
            console.log("fail");

        }

    };


    return (
        <>

            <div class="breadcrumb">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-5 col-lg-5">
                            <div class="part-txt">
                                <h1>Contact Us</h1>
                                <ul>
                                    <li><Link to={"/"}>Home</Link></li>
                                    <li><i class="icofont-double-right"></i></li>
                                    <li class="current">Contact</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="contact">
                <div class="container">
                    <div class="row no-gutters justify-content-between">
                        <div class="col-xl-6 col-lg-6">
                            <div class="form-area">
                                <div class="title">
                                    <h2>Get In Touch:</h2>
                                    {/* <p>Lorem Ipsu is siply dmmyt the pring been thltext industry standard<br/>dummy text ever sice the when an unown</p> */}
                                </div>
                                {/* {ercapacha} */}
                                <form onSubmit={handleSubmit}>
                                    <input type="text" value={state.names} onChange={changeHandler} name="names" placeholder="Name:" required />
                                    <input type="email" value={state.email} onChange={changeHandler} name="email" placeholder="Email:" required />
                                    <textarea placeholder="Message..." value={state.message} name="message"
                                                              defaultValue={""}
                                                              onChange={changeHandler} required></textarea>
                                    <ReCAPTCHA
                                        ref={captch}
                                        sitekey={key}
                                        onChange={onChange}
                                    />
                                    {visible && <p style={{ color: 'red' }}>{ercapacha}</p>}
                                    {visible && <p style={{ color: 'green' }}>{status}</p>}
                                    <button type="submit" name="submit" class="def-btn">Submit Now <i class="icofont-double-right"></i></button>
                                </form>
                            </div>
                        </div>
                        <div class="col-xl-5 col-lg-6">
                            <div class="address-area">

                                <div class="single-box two">
                                    <div class="title">
                                        <h3 style={{ marginBottom: "25px" }}>Contact Details </h3>

                                        <div class="details" style={{ marginTop: "35px !important" }}>
                                            <ul>
                                                <li><span><i class="icofont-clock-time"></i></span>10:00 AM 06:00 PM</li>
                                                <li><span><i class="icofont-email"></i></span>Info@Texasautomations.Com</li>
                                                <li><span><i class="icofont-google-map"></i></span>TEXAS AUTOMATION SERVICES LLC.<br />7162 HARCOURT XING INDIAN LAND, SC 29707-5841</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    )

}
export default Contact;