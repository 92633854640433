import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
   

    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleSidebar = () => {
      setIsCollapsed(!isCollapsed);
      const element = document.getElementById("sidebar");
      const ele = document.getElementById("navbarSupportedContent");
      if (element) {
        element.classList.toggle('collapsed');
        ele.classList.remove('show');

      }
    };
  

    return (
        

        <>

            {/* <body> */}

            <div class="preloader">
                <div class="loader"><img src="assets/images/spinner_texas_automations.gif" alt="image123" /></div>
            </div>


            <div class="header">
                <div class="top-header">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12">
                                <div class="top-right">
                                    <ul>

                                        <li>
                                            <span><i class="icofont-email"></i></span>info@texasautomations.com
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-header">
                    <div class="container">
                        <div class="row no-gutters justify-content-between">
                            <div class="d-xl-none d-lg-none d-flex col-4">
                                <button className={`navbar-toggler ${isCollapsed ? 'collapsed' : ''}`} id="sidebar" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={isCollapsed ? 'false' : 'true'} aria-label="Toggle navigation">
                                    <img src="assets/images/product/btn-ima2_texas_automations.png" alt="image" />
                                </button>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-4">
                                <div class="logo">
                                    <Link to={"/"}>
                                        <img src="assets/images/product/texas_automations_logo.png" alt="LOGO" />
                                    </Link>
                                </div>
                            </div>
                            <div class="col-xl-9 col-lg-9 next">
                                <nav class="navbar navbar-expand-lg navbar-light">
                                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul class="navbar-nav ml-auto">
                                            <li class="nav-item dropdown">

                                                <Link class="nav-link" onClick={toggleSidebar} to={"/"}>Home</Link>


                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link" onClick={toggleSidebar} to={"/about"}>About Us</Link>
                                            </li>
                                            <li class="nav-item dropdown">
                                                <Link class="nav-link" onClick={toggleSidebar} to={"/industrial_panel_pc"}>
                                                    Product 
                                                </Link>
                                                <div class="dropdown-menu" aria-labelledby="serviceDropdown">
                                                    <Link class="dropdown-item" onClick={toggleSidebar} to={"/industrial_panel_pc"}>Industrial Panel PC</Link>
                                                    <Link class="dropdown-item" onClick={toggleSidebar} to={"/fanless_industrial_computer"}>Fanless Industrial Computer</Link>
                                                    <Link class="dropdown-item" onClick={toggleSidebar} to={"/real_time_automation"}>Real Time Automation Controller</Link>
                                                </div>
                                            </li>

                                            {/* </li> */}
                                            <li class="nav-item">
                                                <Link class="nav-link" onClick={toggleSidebar} to={"/contact"}>Contact</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            <div class="col-xl-1 col-lg-1 col-4 d-xl-flex d-lg-flex align-items-center">
                                <div class="side-bar-btn">
                                    <button class="side-bar-show"><img src="assets/images/product/btn-ima2_texas_automations.png" alt="image" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sidebar-bg"></div>
            <div class="main-sidebar">
                <div class="close-btn">
                    <i class="icofont-close-line-circled"></i>
                </div>
                <div class="logo">
                    <img src="assets/images/product/texas_automations_logo.png" alt="LOGO" />
                </div>
                <div class="side-about">
                    <p>Texas is where technology and engineering come together to create solutions for the benefit of our customers, driven without compromise for a world in action.</p>
                </div>

                <div class="side-contact">
                    <h2>contact info</h2>
                    <ul>
                        <li>
                            <i class="icofont-google-map"></i>
                            address
                            <span>TEXAS AUTOMATION SERVICES LLC.<br />7162 HARCOURT XING INDIAN LAND, SC 29707-5841</span>
                        </li>
                        <li>
                            <i class="icofont-email"></i>
                            email
                            <span>info@texasautomations.com</span>
                        </li>

                    </ul>
                </div>
                <div class="socials">
                    <h2>social networks</h2>
                    <Link to={"/"}><i class="icofont-facebook"></i></Link>
                    <Link to={"/"}><i class="icofont-twitter"></i></Link>
                    <Link to={"/"}><i class="icofont-dribbble"></i></Link>
                    <Link to={"/"}><i class="icofont-skype"></i></Link>
                </div>
            </div>

        </>
    )
}
export default Header;