import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./pages/about";
import Contact from "./pages/contact";
import Home from "./pages";
import P1 from "./pages/product1";
import P2 from "./pages/product2";
import P3 from "./pages/product3";
import Header from "./pages/header";
import Footer from "./pages/footer";
import ScrollToTop from "./scroll";



function App() {

  return (
    <Router>
        <Header />
        <ScrollToTop/>
        <Routes>

        {/* <Switch> */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/industrial_panel_pc" element={<P1 />} />
        <Route exact path="/fanless_industrial_computer" element={<P2 />} />
        <Route exact path="/real_time_automation" element={<P3 />} />
       

        </Routes >

        {/* </Switch> */}
        <Footer />
    </Router>

  );
}

export default App;
